@import "components/variables";


.animation__outer {
  height: 500vh;
  position: relative;

  margin-bottom: 30px;

}

.animation__sticky {
  position: sticky;
  top: 0;
}

.animation__wrapper {
  height: 100vh;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.animation__img1 {
  position: absolute;
  z-index: 1;

  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;

}

.animation__img2 {
  position: absolute;
  z-index: 3;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  height: 100%;
}

.animation__txt1 {
  font-size: 130px;
  font-weight: 700;
  color: #fff;
  position: absolute;
  z-index: 5;
  opacity: 0;
  transition: all 1s ease-out;
}

.animation__txt1--1 {
  top: 10%;
  left: -30%;
}

.animation__txt1--2 {
  bottom: 10%;
  right: -30%;
}

.animation__txt2 {
  font-size: 100px;
  font-weight: 700;
  color: #fff;
  position: absolute;
  z-index: 6;
  opacity: 0;
  transition: all 1s ease-out;
}

.animation__txt2--1 {
  top: 30%;
  left: -30%;
}

.animation__txt2--2 {
  bottom: 30%;
  right: -30%;
}

.animation__txt3 {
  width: 800px;
  padding-left: 150px;
  padding-right: 30px;
  padding-bottom: 50px;
  padding-top: 100px;
  max-width: 75%;
  right: 0%;
  top: 100%;
  overflow: hidden;
  color: #fff;
  font-size: 30px;
  position: absolute;
  z-index: 10;
  text-align: right;
  transition: top 1s ease-out;

  opacity: 1;

  p {
    opacity: 0;
    transition: opacity 0.3s ease-out;

    margin-bottom: 20px;

    &:first-child {
      padding-left: 50px;
    }

    &:nth-child(2) {
      padding-left: 50px;
    }

    &:nth-child(3) {
      padding-left: 50px;
    }
  }


}

.threshold {
  height: 15%;
  position: absolute;
  top: 10%;
  width: 100%;
  display: block;
  z-index: -1;
}

.threshold-2 {
  top: 25%;
}

.threshold-3 {
  top: 40%;
}

.threshold-4 {
  top: 55%;
}

.threshold-5 {
  top: 70%;
}

.animation-triangle {
  display: block;
  height: 100%;
  width: 60%;
  background: $main;
  -webkit-clip-path: polygon(0% 100%, 25% 0%, 100% 0%, 100% 100%);
  clip-path: polygon(0% 100%, 25% 0%, 100% 0%, 100% 100%);
  position: absolute;
  top: 0;
  right: -50%;
  z-index: 4;
  opacity: 0;
  transition: right 0.3s ease-out;
}

.gI-scroll-1 {
  .animation__txt1--1 {
    opacity: 1;
    left: 5%;
  }

  .animation__txt1--2 {
    opacity: 1;
    right: 5%;

  }

  .animation__txt3 {
    opacity: 0;
  }

}

.gI-scroll-2 {
  .animation__txt2--1 {
    opacity: 1;
    left: 5%;
  }

  .animation__txt2--2 {
    opacity: 1;
    right: 5%;
  }

  .animation__img2 {
    transition: all 1s ease-out;
    height: 110%;
  }

  .animation__txt3 {
    opacity: 0;
  }
}

.gI-scroll-3 {
  .animation__img2 {
    transition: all 1s ease-out;
    height: 150%;
    left: 5%;
  }

  .animation__txt3 {
    opacity: 1;
    top: 60%;

    p {

      &:first-child {
        opacity: 1;
      }
    }
  }

  .animation-triangle {
    opacity: 1;
    right: 0;
  }
}

.gI-scroll-4 {
  .animation__img2 {
    transition: all 1s ease-out;
    height: 150%;
    left: 5%;
  }

  .animation__txt3 {
    opacity: 1;

    top: 40%;

    p {
      &:first-child {
        opacity: 1;
      }

      &:nth-child(2), &:nth-child(3) {
        opacity: 1;
      }

    }
  }

  .animation-triangle {
    opacity: 1;
    right: 0;
  }


}

.gI-scroll-5 {
  .animation__img2 {
    transition: all 1s ease-out;
    left: -30%;
    height: 1000%;
    opacity: 0;

  }

  .animation__txt3 {
    font-size: 20px;
    top: 0;

    p {
      opacity: 1;
    }

  }

  .animation-triangle {
    opacity: 1;
    right: 0;
  }

}

@media(max-width: $grid-breakpoints-xxl) {
  .animation__txt1 {
    font-size: 100px;
  }
  .animation__txt2 {
    font-size: 80px;
  }

  .animation__txt3 {
    font-size: 28px;
  }

}

@media(max-width: $grid-breakpoints-lg) {
  .animation__txt1 {
    font-size: 80px;
  }
  .animation__txt2 {
    font-size: 50px;
  }
  .animation__txt3 {
    font-size: 24px;
  }
  .gI-scroll-5 .animation__txt3 {
    font-size: 18px;
    top: 0;
  }
}

@media(max-width: $grid-breakpoints-sm) {

  .animation .container {
    padding: 0;

    .btn--arrow {
      margin-left: 20px;
    }
  }
  .animation__txt1 {
    font-size: 70px;
  }
  .animation__txt2 {
    font-size: 40px;
  }
  .animation__txt3 {
    font-size: 20px;
  }
  .gI-scroll-5 .animation__txt3 {
    font-size: 14px;
  }

  .animation__txt3 {
    width: 800px;
    padding: 100px 30px 50px 30px;
    max-width: 100%;

    p {
      padding: 0 !important;
      margin-bottom: 10px;
    }
  }
  .animation-triangle {
    width: 150%;
  }
}

